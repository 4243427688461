import React from 'react';
import { Router } from '@reach/router';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { Activity } from '../components/Activity';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { ActivityCreate } from '../components/Activity/ActivityCreate';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';
import { ActivityDetail } from '../components/Activity/ActivityDetail';

export default function ActivityPage() {
  return (
    <PublicLayout>
      <Router basepath="/activities">
        <>
          <Activity path="/" />
          <PrivateRoute
            component={ActivityCreate}
            path="/create"
            roles={['Administrator', 'Organizer']}
          />
          <ActivityDetail path="/:id" />
          <NotFoundRedirect path="*" />
        </>
      </Router>
    </PublicLayout>
  );
}
