import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Wrapper } from '../Shared/Wrapper';
import SeoHeader from '../Shared/SeoHeader';
import { PageHeader } from '../Shared/PageHeader';
import {
  OptionEnum,
  WebinarStatusEnum,
  WebinarTypeEnum,
} from '../../graphql/__types';
import { WebinarList } from '../Webinar/WebinarList';

export function Activity(_: RouteComponentProps) {
  return (
    <Wrapper>
      <SeoHeader title="Activities" />

      <section className="flex flex-col w-full py-5">
        <PageHeader title="Activities" createPath="/activities/create" />

        <section className="mt-5">
          <section className="text-xl text-black font-bold uppercase">
            Upcoming Webinars
          </section>
          <WebinarList
            status={WebinarStatusEnum.Waiting}
            location="activities"
            count={4}
            isWebinar={OptionEnum.Yes}
          />
        </section>

        <section className="mt-5">
          <section className="text-xl text-black font-bold uppercase">
            NSTW in Region 1 Programs
          </section>
          <WebinarList
            location="normal"
            count={4}
            isWebinar={OptionEnum.No}
            type={WebinarTypeEnum.GeneralActivities}
          />
        </section>

        <section className="mt-5">
          <section className="text-xl text-black font-bold uppercase">
            StarBooks Stakeholders Regional Convention
          </section>
          <WebinarList
            location="normal"
            count={4}
            isWebinar={OptionEnum.No}
            type={WebinarTypeEnum.StarBooks}
          />
        </section>
      </section>
    </Wrapper>
  );
}
