import React from 'react';
import { OptionEnum } from '../../graphql/__types';
import { PageTitle } from '../Shared/PageTitle';
import SeoHeader from '../Shared/SeoHeader';
import { Wrapper } from '../Shared/Wrapper';
import { WebinarForm } from '../Webinar/WebinarForm';
import { RouteComponentProps } from '@reach/router';

export function ActivityCreate(_: RouteComponentProps) {
  return (
    <Wrapper>
      <SeoHeader title={'Create Activity'} />

      <section className="w-full py-5">
        <PageTitle value="Create Activity" />

        <section className="mt-5">
          <section className="divide bg-white border border-gray-100 shadow-md rounded-lg px-4 py-12 md:px-8 w-full md:w-10/12 mx-auto">
            <WebinarForm isWebinar={OptionEnum.No} />
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
